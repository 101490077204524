<script>
import Dropdown from '../inputs/select.vue'
import { stateOptions } from '@/utils'

export default {
  name: 'state_dropdown',
  extends: Dropdown,
  props: {
    label: {
      type: String,
      default: 'State'
    },
    name: {
      type: String,
      default: 'state'
    },
    rules: {
      type: String,
      default: 'required|min:2'
    },
    options: {
      type: Array,
      default () {
        return stateOptions
      }
    }
  }
}
</script>
